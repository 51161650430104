/* Accordion styles */
/* stylelint-disable scss/media-feature-value-dollar-variable */
/* stylelint-disable selector-class-pattern */

.accordion {
  box-sizing: border-box;
  padding: 1px 0;
  border-bottom: 0.5px solid rgb(224 224 224);
}

.accordion__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

.accordion__title h3 {
  margin: 0;
  padding-right: 16px;
}

.accordion__icon {
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease-in-out;
}

.accordion__content {
  display: none;
}

.accordion__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.accordion__item {
  margin-bottom: 24px;
}

.accordion__link {
  color: rgb(111 110 108);
  font-size: 14px;
}

.accordion--open .accordion__icon {
  transform: rotate(180deg) !important;
}

.accordion--open .accordion__content {
  display: block;
}

.accordion li {
  color: rgb(111 110 108);
}

.accordion ul,
.accordion ol {
  padding-left: 24px;
}

/* Media query for larger screen sizes */
@media only screen and (min-width: 768px) {
  .accordion__title h3 {
    font-size: 16px;
  }
}
