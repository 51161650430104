@font-face {
  font-family: "jiotypemedium";
  src: url("~src/assets/fonts/jiotype-medium_0-webfont.woff2") format("woff2"), url("~src/assets/fonts/jiotype-medium_0-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "jiotypebold";
  src: url("~src/assets/fonts/jiotype-bold_0-webfont.woff2") format("woff2"), url("~src/assets/fonts/jiotype-bold_0-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "FS Albert Pro";
  src: url("~src/assets/fonts/FSAlbertPro-Bold.woff2") format("woff2"), url("~src/assets/fonts/FSAlbertPro-Bold.woff") format("woff"), url("~src/assets/fonts/FSAlbertPro-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "jiotypemedium";
  src: url("~src/assets/fonts/jiotype-medium_0-webfont.woff2") format("woff2"), url("~src/assets/fonts/jiotype-medium_0-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "jiotypebold";
  src: url("~src/assets/fonts/jiotype-bold_0-webfont.woff2") format("woff2"), url("~src/assets/fonts/jiotype-bold_0-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "FS Albert Pro";
  src: url("~src/assets/fonts/FSAlbertPro-Bold.woff2") format("woff2"), url("~src/assets/fonts/FSAlbertPro-Bold.woff") format("woff"), url("~src/assets/fonts/FSAlbertPro-Bold.ttf") format("truetype");
  font-display: swap;
}
.scale-up-center {
  animation: scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.slide-top {
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.shadow-inset-center {
  animation: shadow-inset-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -100px, 0);
  }
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shadow-inset-center {
  0% {
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
  }
}
@keyframes slide-up {
  0% {
    transform: translate3d(0, 2rem, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-down {
  0% {
    transform: translate3d(0, -2rem, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-left {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-right-in {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-right-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slide-right {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scale-up {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
@keyframes shine {
  10% {
    opacity: 1;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
@keyframes spinning {
  from {
    transform: translateZ(-5em) rotateY(0deg);
  }
  to {
    transform: translateZ(-5em) rotateY(360deg);
  }
}
* {
  outline: none;
  -webkit-overflow-scrolling: touch;
}

html {
  font-size: 14px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  line-height: 1.15; /* 1 */
  text-size-adjust: 100%; /* 2 */
}

body {
  font-family: "jiotypemedium";
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
body.scroll-lock {
  overflow: hidden !important;
}

input {
  user-select: initial !important;
}

.clearfix {
  clear: both;
}

.full {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

/* stylelint-disable no-empty-source */
@font-face {
  font-family: "jiotypemedium";
  src: url("~src/assets/fonts/jiotype-medium_0-webfont.woff2") format("woff2"), url("~src/assets/fonts/jiotype-medium_0-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "jiotypebold";
  src: url("~src/assets/fonts/jiotype-bold_0-webfont.woff2") format("woff2"), url("~src/assets/fonts/jiotype-bold_0-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "FS Albert Pro";
  src: url("~src/assets/fonts/FSAlbertPro-Bold.woff2") format("woff2"), url("~src/assets/fonts/FSAlbertPro-Bold.woff") format("woff"), url("~src/assets/fonts/FSAlbertPro-Bold.ttf") format("truetype");
  font-display: swap;
}
.d-none {
  display: none;
}

.d-block {
  display: block;
}

@font-face {
  font-family: "jiotypemedium";
  src: url("~src/assets/fonts/jiotype-medium_0-webfont.woff2") format("woff2"), url("~src/assets/fonts/jiotype-medium_0-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "jiotypebold";
  src: url("~src/assets/fonts/jiotype-bold_0-webfont.woff2") format("woff2"), url("~src/assets/fonts/jiotype-bold_0-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "FS Albert Pro";
  src: url("~src/assets/fonts/FSAlbertPro-Bold.woff2") format("woff2"), url("~src/assets/fonts/FSAlbertPro-Bold.woff") format("woff"), url("~src/assets/fonts/FSAlbertPro-Bold.ttf") format("truetype");
  font-display: swap;
}
.merchant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 1.2857142857rem;
  font-family: HelveticaNeuemedium;
  color: #ffffff;
  background: linear-gradient(106deg, rgb(116, 31, 116) 0%, rgb(77, 9, 78) 100%) 0% 0%;
  width: 147px;
  height: 48px;
  border-radius: 12px !important;
  margin: 0 auto !important;
}

/* Accordion styles */
/* stylelint-disable scss/media-feature-value-dollar-variable */
/* stylelint-disable selector-class-pattern */
.accordion {
  box-sizing: border-box;
  padding: 1px 0;
  border-bottom: 0.5px solid rgb(224, 224, 224);
}

.accordion__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

.accordion__title h3 {
  margin: 0;
  padding-right: 16px;
}

.accordion__icon {
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease-in-out;
}

.accordion__content {
  display: none;
}

.accordion__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.accordion__item {
  margin-bottom: 24px;
}

.accordion__link {
  color: rgb(111, 110, 108);
  font-size: 14px;
}

.accordion--open .accordion__icon {
  transform: rotate(180deg) !important;
}

.accordion--open .accordion__content {
  display: block;
}

.accordion li {
  color: rgb(111, 110, 108);
}

.accordion ul,
.accordion ol {
  padding-left: 24px;
}

/* Media query for larger screen sizes */
@media only screen and (min-width: 768px) {
  .accordion__title h3 {
    font-size: 16px;
  }
}
/* .skeleton-transactions {
  margin-top: 32px;
} */
.skeleton-list {
  margin: 0;
  padding: 0;
  border-radius: 8px;
  background-color: white;
}

.skeleton-row {
  display: flex;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 16px;
  padding-right: 16px;
}

.skeleton-title {
  width: 200px;
  height: 16px;
  background-color: #ddd;
  border-radius: 100px;
  margin-bottom: 20px;
}

.skeleton-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.skeleton-thumbnail {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: #ddd;
}

.skeleton-list-title {
  width: 160px;
  height: 12px;
  background-color: #ddd;
  border-radius: 100px;
  margin-bottom: 8px;
}

.skeleton-list-description {
  width: 100px;
  height: 8px;
  background-color: #ddd;
  border-radius: 100px;
}

.skeleton-amount {
  width: 50px;
  height: 10px;
  background-color: #ddd;
  border-radius: 100px;
  margin-left: auto;
}

/* Show only the first 3 items on mobile screens */
@media (max-width: 767px) {
  .skeleton-list li:nth-child(n+4) {
    display: none;
  }
}
/* TransactionDetails skeleton */
.skeleton-transDetails {
  max-width: 250px;
  height: 188px;
}

.transactiondetailsskeleton {
  margin-top: 20px;
  max-width: 584px;
  padding: 2px;
  border-radius: 8px;
}

.skeleton-transactiondetailslist {
  margin: 0px;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
}

.transactiondetailsskeleton,
.skeleton-transactiondetailsrow {
  background-color: #fff;
}

.skeleton-transactiondetailsrow {
  max-width: 584px;
  margin: auto;
  height: 14px;
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  padding: 20px 0px;
}

.skeleton-transdetailsamount {
  margin-left: auto;
  width: 61.7px;
  height: 14px;
  border-radius: 4px;
}

.skeleton-thumbnail1 {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.skeleton-list-title1 {
  width: 249.805px;
  height: 44px;
  margin-top: 24px;
}

.skeleton-list-title2 {
  width: 216.308px;
  height: 20px;
  border-radius: 4px;
  margin-top: 8px;
}

.skeleton-list-title3 {
  width: 241.193px;
  height: 20px;
  margin-top: 8px;
}

.skeleton-list-title4 {
  width: 163px;
}

.skeleton-list-title5 {
  width: 120.352px;
}

.skeleton-list-title6 {
  width: 143.252px;
}

.skeleton-list-title4,
.skeleton-list-title5,
.skeleton-list-title6 {
  height: 14px;
}

.skeleton-list-title1,
.skeleton-list-title2,
.skeleton-list-title3,
.skeleton-list-title4,
.skeleton-list-title5,
.skeleton-list-title6 {
  border-radius: 4px;
}

.skeleton-transdetailsamount,
.skeleton-thumbnail1,
.skeleton-list-title1,
.skeleton-list-title2,
.skeleton-list-title3,
.skeleton-list-title4,
.skeleton-list-title5,
.skeleton-list-title6 {
  background: #E0E0E0;
}

/* Partner Offers */
.skeleton-partner-offers-grid {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  justify-content: center;
}

.skeleton-offer-card {
  flex-basis: calc(50% - 8px);
  margin-right: 16px;
  margin-bottom: 16px;
  max-width: 250px;
  /* background-color: #ddd;
  width: 160px;
  height: 160px; */
}

.skeleton-offer-card:nth-child(2n) {
  margin-right: 0;
}

.skeleton-offer:nth-last-child(-n+2) {
  margin-bottom: 0;
}

.skeleton-offer-image {
  width: 150px;
  height: 150px;
  margin-bottom: 4px;
  border-radius: 8px;
  background-color: #ddd;
}

.skeleton-offer-title {
  margin-top: 10px;
  margin-bottom: 4px;
  background-color: #ddd;
  width: 110px;
  border-radius: 100px;
  height: 18px;
}

.skeleton-offer-description {
  margin: 0;
  background-color: #ddd;
  width: 80px;
  border-radius: 100px;
  height: 12px;
}

@media only screen and (max-width: 768px) {
  .skeleton-partner-offers-grid {
    background-color: transparent;
    padding: 0;
  }
  .skeleton-offer-image {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 768px) {
  .skeleton-offer-card:nth-child(2n) {
    margin-right: 16px;
  }
  .skeleton-offer-card:nth-child(4) {
    margin-right: 0;
  }
  .skeleton-offer-card {
    flex-basis: calc(25% - 12px);
  }
  .skeleton-offer-image {
    width: 114px;
    height: 114px;
  }
}
/* skeleton for profile */
.skeleton__profile__title,
.skeleton__profile,
.skeleton__profile__name,
.skeleton__member__since,
.skeleton__number,
.skeleton__small-title,
.skeleton__title__profile,
.title__skeleton,
.skeleton__progress,
.skeleton__accountsettings__header,
.skeleton__hint {
  background-color: #ddd;
  border-radius: 100px;
}

.skeleton__profile__title {
  width: 200px;
  height: 16px;
  margin-bottom: 24px;
}

.skeleton__profile {
  height: 64px;
  width: 64px;
}

.skeleton__profile__name {
  width: 140px;
  height: 16px;
  margin-top: 16px;
}

.skeleton__member__since {
  margin-top: 20px;
  width: 270px;
  height: 14px;
  line-height: 20px;
}

.skeleton__number {
  width: 108px;
  height: 14px;
  margin-top: 10px;
}

.skeleton__small-title {
  width: 200px;
  height: 16px;
  margin-top: 40px;
}

.skeleton__dot-container {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 8px;
  padding: 8px;
  background-color: #ddd;
}

.title__skeleton {
  width: 160px;
  height: 14px;
}

.skeleton__progress {
  width: 200px;
  height: 14px;
  margin-top: 10px;
}

.skeleton__button {
  border: none;
  max-width: 584px;
  border-radius: 4px;
  background: #ddd;
  height: 50px;
  margin-top: 24px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.skeleton__points {
  width: 16px;
  height: 16px;
  background: #ddd;
  border-radius: 4px;
  margin-left: auto;
}

.skeleton__more-title {
  background: #ddd;
  width: 100px;
  height: 14px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

/* skeleton for partner-deatils */
.skeleton__header,
.skeleton__complete,
.skeleton__profile-title,
.skeleton__partner__details_title,
.skeleton__value,
.skeleton__edit,
.skeleton__interest__description,
.skeleton__help,
.skeleton-header-description,
.skeleton-coupon-steps,
.skeleton-coupon-description {
  background: #ddd;
  border-radius: 100px;
}

.skeleton__header {
  width: 200px;
  height: 18px;
}

.skeleton__complete {
  width: 560px;
  height: 16px;
  margin-top: 16px;
}

.skeleton__profile-title {
  width: 180px;
  height: 16px;
}

.skeleton__profile-title {
  width: 170px;
  margin-top: 40px;
  margin-bottom: 16px;
  height: 16px;
}

.skeleton__partner__details_title {
  width: 140px;
  height: 12px;
}

.skeleton__value {
  width: 80px;
  height: 14px;
  margin-top: 5px;
}

.skeleton__accountsettings__header {
  width: 200px;
  height: 16px;
}

.skeleton__hint {
  width: 340px;
  height: 14px;
  margin-top: 12px;
}

.skeleton__edit {
  width: 40px;
  height: 14px;
}

.skeleton__interest__description {
  width: 560px;
  height: 16px;
  margin-top: 12px;
  margin-bottom: 32px;
}

.skeleton__base-category {
  background: #ddd;
  width: 115px;
  height: 28px;
  border-radius: 999999px;
  border: 1px #d3e3f8 solid;
}

.skeleton__help {
  width: 160px;
  height: 14px;
  margin-bottom: 24px;
  margin-top: 40px;
}

.coupon-card-skeleton {
  background: #ddd !important;
  margin-bottom: 10px;
  gap: 0px !important;
}

.skeleton-header-description {
  width: 460px;
  height: 16px;
  margin: 2px;
}

.skeleton-coupon-steps {
  width: 410px;
  height: 16px;
  margin: 14px;
}

.skeleton-coupon-description {
  width: 160px;
  height: 16px;
  margin-bottom: 30px;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(13, 13, 12, 0.8);
}

.modal-content {
  background-color: rgb(254, 254, 254);
  margin: 24px auto;
  padding: 36px 24px;
  border: 1px solid rgb(136, 136, 136);
  width: 75%;
  max-width: 588px;
  border-radius: 12px;
  position: relative;
  height: calc(100% - 120px);
}

.close {
  position: absolute;
  top: 32px;
  right: 16px;
  font-size: 2rem;
  font-weight: 700;
  color: rgb(170, 170, 170);
  cursor: pointer;
}