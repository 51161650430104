$primary-color: rgb(255 255 255);
$secondary-color: rgb(191 162 99);
$background-color1: rgb(255 255 255);
$background-color2: rgb(17 2 26);
$button-bg-color: rgb(102 0 141);
$border-color1: rgb(66 50 75);
$border-color2: rgb(64 52 71);
$invalid-input: rgb(255 0 0);
$midnight-pearl: rgb(111 28 111);
$light-gray: rgb(213 213 213);
$flame: rgb(240 75 36);
$button-border-color: rgb(102 35 107);
$primary-font-family: 'jiotypemedium';
$primary-font-size: 14px;
$primary-font-size-value: 14;
$breakpoints: (
  'medium': (
    min-width: 600px,
  ),
  'large': (
    min-width: 800px,
  ),
  'huge': (
    min-width: 1800px,
  ),
);
$iphone-min-width: 320px;
$iphone-max-width: 480px;
$iphone-five-max-width: 568px;
$iphone-six-to-eight-min-width: 375px;
$iphone-six-to-eight-max-width: 667px;
$iphone-six-to-eight-plus-min-width: 414px;
$iphone-six-to-eight-plus-max-width: 736px;
$iphone-x-min-width: 375px;
$iphone-x-max-width: 812px;
$mobile-max-width: 480px;
$max-width: 576px;
$min-width: 600px;
$min-width1: 768px;
$min-width2: 992px;
$min-width3: 1200px;
$min-width4: 1600px;
$min-width5: 1900px;
$min-width6: 1368px;
$z-index-1: -1;
$z-index: 1;
$z-index2: 2;
$z-index6: 6;
$z-index5: 5;
$z-index9: 9;
$z-index10: 10;
$z-index11: 11;
$z-index20: 29;
$z-index1000: 1000;
$z-index-high: 9999;
$max-width-container: 984px;
