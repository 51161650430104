@import './../helpers/variables';

* {
  outline: none;
  -webkit-overflow-scrolling: touch;
}

html {
  font-size: $primary-font-size;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  line-height: 1.15; /* 1 */
  text-size-adjust: 100%; /* 2 */
}

body {
  font-family: $primary-font-family;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  &.scroll-lock {
    overflow: hidden !important;
  }
}

input {
  user-select: initial !important;
}

.clearfix {
  clear: both;
}

.full {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
