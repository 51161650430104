/* .skeleton-transactions {
  margin-top: 32px;
} */

.skeleton-list {
    margin: 0;
    padding: 0;
    border-radius: 8px;
    background-color: white;
  }
  
  .skeleton-row {
    display: flex;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .skeleton-title {
    width: 200px;
    height: 16px;
    background-color: #ddd;
    border-radius: 100px;
    margin-bottom: 20px;
  }
  
  .skeleton-row {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .skeleton-thumbnail {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #ddd;
  }
  
  .skeleton-list-title {
    width: 160px;
    height: 12px;
    background-color: #ddd;
    border-radius: 100px;
    margin-bottom: 8px;
  }
  
  .skeleton-list-description {
    width: 100px;
    height: 8px;
    background-color: #ddd;
    border-radius: 100px;
  }
  
  .skeleton-amount {
    width: 50px;
    height: 10px;
    background-color: #ddd;
    border-radius: 100px;
    margin-left: auto;
  }
  
  /* Show only the first 3 items on mobile screens */
  @media (max-width: 767px) {
    .skeleton-list li:nth-child(n+4) {
      display: none;
    }
  }
 /* TransactionDetails skeleton */
 
.skeleton-transDetails {
  max-width: 250px;
  height: 188px;
}
.transactiondetailsskeleton{
  margin-top: 20px;
  max-width: 584px;
  padding: 2px;
  border-radius: 8px;

}

.skeleton-transactiondetailslist{
  margin: 0px;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
}
.transactiondetailsskeleton,
.skeleton-transactiondetailsrow{
  background-color: #fff;

}

.skeleton-transactiondetailsrow {
  max-width: 584px;
  margin: auto;
  height: 14px;
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  padding: 20px 0px;
}


.skeleton-transdetailsamount {
  margin-left: auto;
  width: 61.7px;
  height: 14px;
  border-radius: 4px;
}

.skeleton-thumbnail1 {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.skeleton-list-title1 {
  width: 249.805px;
  height: 44px;
  margin-top: 24px;
}

.skeleton-list-title2 {
  width: 216.308px;
  height: 20px;
  border-radius: 4px;
  margin-top: 8px;
}

.skeleton-list-title3 {
  width: 241.193px;
  height: 20px;
  margin-top: 8px;
}

.skeleton-list-title4 {
  width: 163px;

}

.skeleton-list-title5 {
  width: 120.352px;
}

.skeleton-list-title6 {
  width: 143.252px;

}
.skeleton-list-title4,
.skeleton-list-title5,
.skeleton-list-title6{
  height: 14px;
}
.skeleton-list-title1,
.skeleton-list-title2,
.skeleton-list-title3,
.skeleton-list-title4,
.skeleton-list-title5,
.skeleton-list-title6{
  border-radius: 4px;
}
.skeleton-transdetailsamount,
.skeleton-thumbnail1 ,
.skeleton-list-title1,
.skeleton-list-title2,
.skeleton-list-title3,
.skeleton-list-title4,
.skeleton-list-title5,
.skeleton-list-title6{
  background: #E0E0E0;
}
  
  
  /* Partner Offers */
  
  .skeleton-partner-offers-grid {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    justify-content: center;
  }
  
  .skeleton-offer-card {
    flex-basis: calc(50% - 8px);
    margin-right: 16px;
    margin-bottom: 16px;
    max-width: 250px;
    /* background-color: #ddd;
    width: 160px;
    height: 160px; */
  }
  
  .skeleton-offer-card:nth-child(2n) {
    margin-right: 0;
  }
  
  .skeleton-offer:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
  
  .skeleton-offer-image {
    width: 150px;
    height: 150px;
    margin-bottom: 4px;
    border-radius: 8px;
    background-color: #ddd;
  }
  
  .skeleton-offer-title {
    margin-top: 10px;
    margin-bottom: 4px;
    background-color: #ddd;
    width: 110px;
    border-radius: 100px;
    height: 18px;

  }
  
  .skeleton-offer-description {
    margin: 0;
    background-color: #ddd;
    width: 80px;
    border-radius: 100px;
    height: 12px;
  }
  
  @media only screen and (max-width: 768px) {
    .skeleton-partner-offers-grid {
      background-color: transparent;
      padding: 0;
    }
  
    .skeleton-offer-image {
      width: 120px;
      height: 120px;
    }
  
  }
  
  @media only screen and (min-width: 550px) and (max-width: 768px) {
    .skeleton-offer-card:nth-child(2n) {
      margin-right: 16px;
    }
  
    .skeleton-offer-card:nth-child(4) {
      margin-right: 0;
    }
  
    .skeleton-offer-card {
      flex-basis: calc(25% - 12px);
    }
  
    .skeleton-offer-image {
      width: 114px;
      height: 114px;
    }
  }


  /* skeleton for profile */



.skeleton__profile__title,
.skeleton__profile,
.skeleton__profile__name,
.skeleton__member__since,
.skeleton__number,
.skeleton__small-title,
.skeleton__title__profile,
.title__skeleton,
.skeleton__progress,
.skeleton__accountsettings__header,
.skeleton__hint{
  background-color: #ddd;
  border-radius: 100px;
}

.skeleton__profile__title
{
    width: 200px;
    height: 16px;
    margin-bottom: 24px;
}
.skeleton__profile{
      height: 64px;
      width: 64px;
      
}
.skeleton__profile__name{
    width: 140px;
    height: 16px;
    margin-top: 16px;
  }
.skeleton__member__since{
  margin-top: 20px;
  width: 270px;
  height: 14px;
  line-height: 20px;
}

.skeleton__number{
  width: 108px;
  height: 14px;
  margin-top: 10px;
}
.skeleton__small-title{
  width: 200px;
  height: 16px;
  margin-top: 40px;
}
.skeleton__dot-container{
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 8px;
  padding: 8px;
  background-color: #ddd;
}
.title__skeleton{
  width: 160px;
  height: 14px;
}
.skeleton__progress{
  width: 200px;
  height: 14px;
  margin-top: 10px;
}
.skeleton__button {
  border: none;
  max-width: 584px;
  border-radius: 4px;
  background: #ddd;
  height: 50px;
  margin-top: 24px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.skeleton__points{
  width: 16px;
  height: 16px;
  background: #ddd;
  border-radius: 4px;
  margin-left: auto;
}
.skeleton__more-title{
  background: #ddd;
  width: 100px;
  height: 14px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}


/* skeleton for partner-deatils */

.skeleton__header,
.skeleton__complete,
.skeleton__profile-title,
.skeleton__partner__details_title,
.skeleton__value,
.skeleton__edit,
.skeleton__interest__description,
.skeleton__help,
.skeleton-header-description,
.skeleton-coupon-steps,
.skeleton-coupon-description{
  background:#ddd;
  border-radius: 100px;
}
.skeleton__header{
  width: 200px;
  height: 18px;
}
.skeleton__complete{
  width: 560px;
  height: 16px;
  margin-top: 16px;
}
.skeleton__profile-title{
  width: 180px;
  height: 16px;
}

.skeleton__profile-title{
  width: 170px;
  margin-top: 40px;
  margin-bottom: 16px;
  height: 16px;
}
.skeleton__partner__details_title{
  width: 140px;
  height: 12px;
}
.skeleton__value{
  width: 80px;
  height: 14px;
  margin-top: 5px;
}
.skeleton__accountsettings__header{
  width: 200px;
  height: 16px;
}
.skeleton__hint{
  width: 340px;
  height: 14px;
  margin-top: 12px;
}
.skeleton__edit{
  width: 40px;
  height: 14px;
}

.skeleton__interest__description{
  width: 560px;
  height: 16px;
  margin-top: 12px;
  margin-bottom: 32px;
}
.skeleton__base-category{
    background: #ddd;
    width: 115px;
    height: 28px;
    border-radius: 999999px;
    border: 1px #d3e3f8 solid;
}
.skeleton__help{
  width: 160px;
  height: 14px;
  margin-bottom: 24px;
  margin-top: 40px;
}
.coupon-card-skeleton{
  background: #ddd !important;
  margin-bottom: 10px;
  gap: 0px !important;
}
.skeleton-header-description{
  width: 460px;
  height: 16px;
  margin:2px;
}
.skeleton-coupon-steps{
  width: 410px;
  height: 16px;
  margin: 14px;
}
.skeleton-coupon-description{

  width: 160px;
  height: 16px;
  margin-bottom: 30px;
}
