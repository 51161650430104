@import 'variables';

@function get-font-family-of-weight ($weight) {
  @return $weight;
}

@font-face {
  font-family: get-font-family-of-weight($weight:'jiotypemedium');
  src:
    url('~src/assets/fonts/jiotype-medium_0-webfont.woff2') format('woff2'),
    url('~src/assets/fonts/jiotype-medium_0-webfont.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: get-font-family-of-weight($weight:'jiotypebold');
  src:
    url('~src/assets/fonts/jiotype-bold_0-webfont.woff2') format('woff2'),
    url('~src/assets/fonts/jiotype-bold_0-webfont.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: get-font-family-of-weight($weight:'FS Albert Pro');
  src:
    url('~src/assets/fonts/FSAlbertPro-Bold.woff2') format('woff2'),
    url('~src/assets/fonts/FSAlbertPro-Bold.woff') format('woff'),
    url('~src/assets/fonts/FSAlbertPro-Bold.ttf') format('truetype');
  font-display: swap;
}
