.modal {
  display: block;
  position: fixed;
  z-index: $z-index;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(13 13 12 / 80%);
}

.modal-content {
  background-color: rgb(254 254 254);
  margin: 24px auto;
  padding: 36px 24px;
  border: 1px solid rgb(136 136 136);
  width: 75%;
  max-width: 588px;
  border-radius: 12px;
  position: relative;
  height: calc(100% - 120px);
}

.close {
  position: absolute;
  top: 32px;
  right: 16px;
  font-size: 2rem;
  font-weight: 700;
  color: rgb(170 170 170);
  cursor: pointer;
}
