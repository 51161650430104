/* stylelint-disable */

@import './../helpers/variables';
@import '/src/assets/styles/helpers/mixins';

body {
  font-family: jiotypemedium, sans-serif;
  font-size: 14px;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-wrap: pretty;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  // overscroll-behavior-y: none;
}

.root {
  display: flex;
  height: 100%;
  flex-direction: column;
}

a {
  text-decoration: none;
  cursor: pointer;
}

p {
  color: rgb(111 110 108);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'FS Albert Pro', sans-serif;
  margin: 0;
  font-weight: 400;
  color: rgb(13 13 12);
}

/* You can add global styles to this file, and also import other style files */
.heading-xxl {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 16px;
  letter-spacing: -1px;
}

.heading-xl {
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 16px;
}

.heading-l {
  font-size: 28px;
  line-height: 32px;
}

.heading-m {
  font-size: 20px;
  line-height: 24px;
}

.heading-s {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
}

.heading-s-mobile {
  font-size: 20px;
  line-height: 24px;
}

.heading-xs {
  font-size: 14px;
  line-height: 20px;
}

.body-l {
  font-size: 20px;
  line-height: 24px;
}

.body-m {
  font-size: 16px;
  line-height: 24px;
}

.body-s {
  font-size: 14px;
  line-height: 20px;
}

.body-xs {
  font-size: 12px;
  line-height: 16px;
}

.body-xs-caps {
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Media query for tablet screen sizes */
@media only screen and (min-width: 500px) {
  /* Override styles for tablet */
  .heading-xxl {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 24px;
  }

  .heading-xl {
    font-size: 40px;
    line-height: 44px;
  }

  .heading-l {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 16px;
  }

  .heading-m {
    font-size: 32px;
    line-height: 36px;
  }

  .heading-s {
    font-size: 24px;
    line-height: 28px;
  }

  .heading-xs {
    font-size: 14px;
    line-height: 20px;
  }
}

/* Media query for desktop screen sizes */
@media only screen and (min-width: 1024px) {
  /* Override styles for desktop */
  .heading-xxl {
    font-size: 72px;
    line-height: 72px;
    margin-bottom: 32px;
  }

  .heading-xl {
    font-size: 56px;
    line-height: 56px;
    margin-bottom: 24px;
  }

  .heading-xs {
    margin-bottom: 24px;
  }

  .h-eyebrow {
    margin: 12px 0;
  }
}

/* Homepage styles */

.h-eyebrow {
  font-family: jiotypemedium, sans-serif;
  color: rgb(244 134 78);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 8px 0;
}

// .h-eyebrow__hero {
//   color: rgb(245 198 164);
//   background:
//     linear-gradient(
//       148deg,
//       rgb(245 213 164) 3.39%,
//       rgb(245 198 164) 66.34%,
//       rgb(163 100 62) 116.8%
//     );
//   background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
// }

.p-home {
  color: rgb(111 110 108);
  font-size: 16px;
  line-height: 24px;

  /* 150% */
}

/* Modifiers */

.heading-s-mobile {
  font-size: 20px;
  line-height: 24px;
}

.white {
  color: white;
}

.tangerine {
  color: rgb(244 134 78);
}

.center {
  text-align: center;
}

.jiotype {
  font-family: jiotypemedium, sans-serif;
}

.ls-25 {
  letter-spacing: -0.25px;
}

.margin-l {
  margin-bottom: 16px;
}

.mb-0 {
  margin-bottom: 0;
}

/* Media query for desktop screen sizes */
@media only screen and (min-width: 1024px) {
  .margin-l {
    margin-bottom: 40px;
  }
}

/* ------------------------------ General styles ------------------------------ */

body {
  background-color: rgb(247 247 247);
  margin: 0;
}

body.page__white-background {
  background-color: white;
}

.outer-container {
  margin-top: 32px;
  margin-left: 20px;
  margin-right: 20px;
}

.inner-container {
  max-width: 984px;
  margin: auto;
}

.main__dashboard {
  margin-top: 72px;
  display: flex;
  flex-direction: column;
}

.gray-box {
  border: darkgray 20px solid;
  height: 500px;
  background-color: lightgray;
  margin-bottom: 20px;
  box-sizing: border-box;
}

/* ------------------------------ Button styles ------------------------------ */

.btn {
  font-family: JioType-Medium, sans-serif;
  background-color: rgb(0 51 128);
  color: rgb(255 255 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  height: 48px;
  padding: 0 20px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  line-height: 48px;
  user-select: none;
}

/* Disabled button styles */
.btn:disabled {
  cursor: not-allowed;
  background-color: rgb(211 227 248);
  color: rgb(123 172 234);
}

a.btn.btn-disabled {
  cursor: not-allowed;
  background-color: rgb(211 227 248);
  color: rgb(123 172 234);
}

.btn__wide {
  width: 100%;
}

.btn.hero__button {
  padding: 0 24px;
  min-width: 200px;
}

.btn__sign-in {
  width: 130px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn img {
  vertical-align: middle;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .btn.btn__sign-in {
    width: 100px;
    height: 32px;
  }

  a.btn.btn__sign-in {
    line-height: 32px;
  }
}

a.btn {
  display: inline-block;
  line-height: 48px;
}

a.btn__sign-in {
  line-height: 40px;
  padding: 0;
}

/* Define the animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the loader image */
.btn-loader {
  animation: spin 1s linear infinite;
}

/* ------------------------------ Utilities ------------------------------ */
.no-scroll {
  overflow: hidden;
}

.contain-size {
  contain: size style;
}

.see-all-link,
.email-link {
  color: rgb(0 51 128);
  font-size: 14px;
  font-family: jiotypebold, sans-serif;
}

.see-all-link {
  font-family: jiotypemedium;
  text-transform: uppercase;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .outer-container {
    margin-top: 24px;
  }
}

/* ------------------------------ Column stuff ------------------------------ */

/* Page load animation */
.page__gray-background {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Header animation */
// .navbar-bg {
// animation: slideDown 0.5s ease-in-out forwards;
// }

/* Balance header animation */
.balance-header {
  animation: slideDown 0.5s ease-in-out forwards;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

//TNC
.terms h2,
.privacy h2 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 8px;
}

.modal .terms h1,
.modal .privacy h1 {
  font-size: 20px;
  margin-bottom: 24px;
}

.terms li,
.privacy li {
  color: rgb(111 110 108);
  line-height: 20px;
  margin-bottom: 8px;
}

.terms p,
.privacy p {
  font-size: 14px;
  color: rgb(111 110 108);
  line-height: 20px;
}

.terms ol {
  padding-inline-start: 24px;
}
.no-list-style{
  list-style: none;
}

.ol__integer {
  margin-bottom: 24px;
}

.terms ul {
  padding-inline-start: 0;
}

.terms ul:not([type='a']) {
  list-style: none;
}

.terms ul[type='a'] {
  list-style-type: lower-alpha;
}

.privacy__item {
  font-size: 14px;
  color: rgb(111 110 108);
  line-height: 20px;
  margin-bottom: 20px;
}

.privacy h3 {
  font-size: 16px;
}

.modal .modal__scrollable {
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}

@media screen and (max-width: 480px) {
  .outer-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.points__text {
  p {
    font-size: 14px;
    font-family: JioTypeMedium;
    color: rgb(13 13 12);
    line-height: 20px;
    margin: 0 !important;
  }
}